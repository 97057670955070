<template>
  <div class="bg center">
    <bread-crumb></bread-crumb>
    <div class="item-top">
      <div class="item-left">
        <div class="tx-box">
          <img :src="user.headportrait"/>
        </div>
        <div class="text-box">
          <span class="text-hy"><a>{{ decodeURIComponent(user.user_name) }}</a>，欢迎您！</span>
          <!--                    <span :class="[status== '0' ? 'sfsm-yes': '',status=='1'? 'sfsm-no': '']">已实名</span>-->
          <!-- <span class="sfsm sfsm-no" v-if="show">未实名</span> -->
          <!--          <span class="text-id">ID：<a>{{ user.user_name }}</a></span>-->
        </div>
      </div>
      <div class="item-right">
        <!--        <div class="item-card">-->
        <!--          <div class="title">-->
        <!--            我的积分-->
        <!--          </div>-->
        <!--          <div class="num">-->
        <!--            222-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="item-card">-->
        <!--          &lt;!&ndash;                    <div class="title">&ndash;&gt;-->
        <!--          &lt;!&ndash;                        我的签到天数&ndash;&gt;-->
        <!--          &lt;!&ndash;                    </div>&ndash;&gt;-->
        <!--          <div class="btn-qd">-->
        <!--            签到-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="item-card">-->
        <!--          <div class="title">-->
        <!--            我的勋章-->
        <!--          </div>-->
        <!--          <div class="num">-->
        <!--            &#45;&#45;-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="profile center">
      <el-row>
        <el-col :span="6">
          <div class="box">
            <div class="menu-box">
              <div class="list-title">
                <p class="cn">个人中心</p>
                <p class="en">USER CENTER</p>
              </div>
              <ul class="left-menu">
                <li v-for="(item, index) of this.$store.state.tsWckl" :key="index" :class="{'active': item.isActive}"
                    @click="showInfo(item.path,index)">
                  <i class="left-line"></i>
                  <div :class="pic[index]" class="pic-box"></div>
                  <!--                                    <i :class="'el-icon-'+item.activeClass"></i>-->
                  <div class="li-title">
                    {{ item.title }}
                  </div>
                  <div v-if="item.showTag" class="tag">{{ item.count }}</div>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="box">
            <router-view></router-view>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

import storage from '@/utils/storage'
import breadCrumb from '@/components/common/breadCrumb'
import {queryWck} from "../../api/personService";
import {getHomeSystem} from "@/api/homeService";

export default {
  components: {
    breadCrumb,
  },
  data() {
    return {
      status: 0,
      user: {},
      leftMenu: [
        {
          title: '我的信息',
          path: 'personal',
          isActive: true,
          activeClass: 's-home',
          showTag: false
        },
        {
          title: '我的关注',
          path: 'follow',
          isActive: false,
          activeClass: 'star-on',
          showTag: false
        },
        {
          title: '我的收藏',
          path: 'collection',
          isActive: false,
          activeClass: 'star-on',
          showTag: false
        },
        // {
        //     title: '我的积分',
        //     path: 'pwdset',
        //     isActive: false,
        //     activeClass: 'lock',
        //     showTag: false
        // },
        // {
        //     title: '我的等级',
        //     path: 'pwdset',
        //     isActive: false,
        //     activeClass: 'lock',
        //     showTag: false
        // }
      ],
      pic: [
        "pic1",
        "pic2",
        "pic3",
        "pic4",
        "pic5",
        "pic6",
      ],
    }
  },
  methods: {
    showInfo(path, idx) {
      this.$router.push({
        path: '/profile/' + path
      })
      this.leftMenu.map((item, index) => {
        item.isActive = idx == index ? true : false
      })
      this.curShow = idx;
    }
  },
  created() {
    // 通过content高度，控制页脚的位置
    if (this.$route.name.indexOf('profile') != -1) {
      document.getElementsByClassName('content')[0].style.minHeight = 'calc(100vh - 140px)';
    }

  },
  mounted() {
    this.user = storage.get('user');
    console.log(this.user)
    if (this.user.headportrait == null || this.user.headportrait == '') {
      this.user.headportrait = '/image/dheader.jpg';
    }
    getHomeSystem().then(res => {
      this.$store.commit("addHomeSystem", res.data);
      //是否显示投诉建议
      if (res.data.kqtsjy) {
        queryWck({}).then(res => {
          this.leftMenu.push({
                title: '我的留言',
                path: 'tsjyList',
                pic: require('@/assets/images/icon-unshoucang.png'),
                isActive: false,
                activeClass: 'circle-plus',
                showTag: res.data > 0 ? true : false,
                count: res.data,
              },
          )
          this.$store.commit("updateTsWckl", this.leftMenu);
        })
      } else {
        this.$store.commit("updateTsWckl", this.leftMenu);
      }
    })
  }
}
</script>
<style lang="scss">
.bg {
  // padding: 20px 0 20px;
  // background-color:#f9f9f9;
  .item-top {
    width: 100%;
    height: 141px;
    background: url(../../assets/images/grzx/top-bg.png) no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .item-left {
      width: 580px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: center;
      .tx-box {
        background: #FFFFFF;
        border: 1px solid #CFDEE6;
        margin-left: 40px;
      }

      .tx-box, .tx-box img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      .text-box {
        display: flex;
        flex-direction: column;
        margin-left: 40px;

        .text-hy {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 32px;
        }

        .sfsm-yes {
          display: block;
          width: 60px;
          height: 20px;
          border-radius: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          text-align: center;
          margin: 10px 0px 16px 0px;
          background: rgba(0, 179, 119, 0.6);
        }

        .sfsm-no {
          display: block;
          width: 60px;
          height: 20px;
          border-radius: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          text-align: center;
          margin: 10px 0px 16px 0px;
          background: rgba(153, 153, 153, 1);
        }

        .text-id {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
        }
      }
    }

    .item-right {
      width: calc(100% - 581px);
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .item-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        height: 60px;
        box-shadow: -1px 0px 0px 0px #A3BECC;

        .title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          height: 18px;
        }

        .num {
          margin-top: auto;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #294766;
          line-height: 26px;
        }

        .btn-qd {
          width: 80px;
          height: 28px;
          background: #009AE5;
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28px;
          text-align: center;
          //margin-top: auto;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .profile {
    .el-row {
      // margin-left: 0px !important;
      // margin-right: 0px !important;

      .box {
        background-color: #fff;
        min-height: 440px;
      }

      .el-col-6 {
        // padding-left:0px !important;
        // padding-right:0px !important;
        // border:1px solid #999999;
        width: 240px;
        border: 1px solid #ececec;
        border-right: 0px solid #ececec;

        .menu-box {
          min-height: 440px;

          .list-title {
            height: 80px;
            background: url(../../assets/images/grzx/bg-grzx.png);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .cn {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #009AE5;
              line-height: 26px;
            }

            .en {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #009AE5;
              line-height: 16px;
              letter-spacing: 1px;
              margin-top: 6px;
            }
          }

          .left-menu {
            li {
              width: 100%;
              height: 60px;
              background: rgba(255, 255, 255, 0);
              box-shadow: 0px 1px 0px 0px #CFDEE6;
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;

              .left-line {
                display: inline-block;
                width: 6px;
                height: 59.2px;
                background: #FFFFFF;
              }

              .li-title {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 18px;
                margin-left: 16px;
              }

              .pic-box {
                width: 16px;
                height: 16px;
                background-size: 16px 16px;
                margin-left: 34px;
              }

              .tag {
                width: 20px;
                height: 20px;
                background: #FF3333;
                border-radius: 50%;
                margin-left: 65px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;
                text-align: center;
              }

              .pic1 {
                background: url(../../assets/images/grzx/icon-xx01.png);
              }

              .pic2 {
                background: url(../../assets/images/grzx/icon-sc02.png);
              }

              .pic3 {
                background: url(../../assets/images/grzx/icon-gz02.png);
              }

              .pic4 {
                background: url(../../assets/images/grzx/icon-jf02.png);
              }

              .pic5 {
                background: url(../../assets/images/grzx/icon-dj02.png);
              }

              .pic6 {
                background: url(../../assets/images/grzx/icon-ts02.png);
              }

            }

            .active {
              width: 240px;
              height: 60px;
              background: rgba(255, 255, 255, 0);
              box-shadow: 0px 1px 0px 0px #CFDEE6;

              .left-line {
                display: inline-block;
                width: 6px;
                height: 59.2px;
                background: #0099E6;
              }

              .li-title {
                font-weight: bold;
                color: #0099E6;
              }

              .pic1 {
                background: url(../../assets/images/grzx/icon-xx02.png);
              }

              .pic2 {
                background: url(../../assets/images/grzx/icon-sc01.png);
              }

              .pic3 {
                background: url(../../assets/images/grzx/icon-gz01.png);
              }

              .pic4 {
                background: url(../../assets/images/grzx/icon-jf01.png);
              }

              .pic5 {
                background: url(../../assets/images/grzx/icon-dj01.png);
              }

              .pic6 {
                background: url(../../assets/images/grzx/icon-ts01.png);
              }
            }
          }
        }
      }

      .el-col-18 {
        width: calc(100% - 240px);
        border: 1px solid #ececec;
        min-height: 440px;
      }
    }
  }
}
</style>
